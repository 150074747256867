<template>
  <div>
    <div
      v-for="(row,index) in dataList[productType].productList"
      :key="index"
    >
      <b-row class="d-flex justify-content-center align-items-center">
        <b-col>
          <div class="font-weight-light">
            <b-form-group
              label="Ürün"
              label-for="id_products"
            >
              <h4>{{ row.products }}</h4>
              <!--                  <validation-provider-->
              <!--                    #default="{ errors }"-->
              <!--                    name="Ürün"-->
              <!--                    rules="required"-->
              <!--                  >-->
              <!--                    <v-select-->
              <!--                      id="id_products"-->
              <!--                      v-model="row.id_products"-->
              <!--                      :options="productLists"-->
              <!--                      label="title"-->
              <!--                      :reduce="item => item.id"-->
              <!--                      placeholder="Seçiniz"-->
              <!--                    >-->
              <!--                      <template v-slot:option="option">-->
              <!--                        <div>-->
              <!--                          {{ option.pcode }} - {{ option.title }}-->
              <!--                        </div>-->
              <!--                        <div v-if="option.product_groups">-->
              <!--                          <small class="text-primary">{{ option.product_groups }}</small>-->
              <!--                        </div>-->
              <!--                      </template>-->
              <!--                      <template v-slot:selected-option="option">-->
              <!--                        <div>-->
              <!--                          {{ option.title }}-->
              <!--                        </div>-->
              <!--                      </template>-->
              <!--                    </v-select>-->
              <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <div class="font-weight-light">
            <b-form-group
              label="Miktar"
              label-for="amount"
            >
              <h4>{{ row.amount }} <span class="text-primary"> {{ row.units }}</span></h4>

              <!--                  <validation-provider-->
              <!--                    #default="{ errors }"-->
              <!--                    name="Miktar"-->
              <!--                    rules="required"-->
              <!--                  >-->
              <!--                    <b-input-group>-->
              <!--                      <cleave-->
              <!--                        id="amount"-->
              <!--                        v-model="row.amount"-->
              <!--                        class="form-control"-->
              <!--                        :raw="true"-->
              <!--                        :options="options"-->
              <!--                        placeholder="Giriniz"-->
              <!--                      />-->
              <!--                      <template #append>-->
              <!--                        <b-dropdown-->
              <!--                          v-model="row.id_units"-->
              <!--                          text="Dropdown"-->
              <!--                          variant="outline-primary"-->
              <!--                        >-->
              <!--                          <template #button-content>-->
              <!--                            {{ !selectedUnitTitle[index] ? row.units ? row.units : 'Seçiniz' : selectedUnitTitle[index] }}-->
              <!--                          </template>-->
              <!--                          <div-->
              <!--                            v-for="(item,indexx) in units"-->
              <!--                            :key="indexx"-->
              <!--                          >-->
              <!--                            <b-dropdown-item @click="selectDropdown(item.id,index)">-->
              <!--                              {{ item.title }}-->
              <!--                            </b-dropdown-item>-->
              <!--                          </div>-->
              <!--                        </b-dropdown>-->
              <!--                      </template>-->
              <!--                    </b-input-group>-->
              <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group
            label="Liste Birim Fiyatı"
            label-for="listed_price"
          >
            <h4>{{ row.listed_price | toCurrency }} <span class="text-primary"> {{ row.currency }}</span></h4>

            <!--                <validation-provider-->
            <!--                  #default="{ errors }"-->
            <!--                  name="Liste Birim Fiyatı"-->
            <!--                  rules="required"-->
            <!--                >-->
            <!--                  <b-input-group>-->
            <!--                    <cleave-->
            <!--                      id="listed_price"-->
            <!--                      v-model="row.listed_price"-->
            <!--                      class="form-control"-->
            <!--                      :raw="true"-->
            <!--                      :options="options"-->
            <!--                      placeholder="Giriniz"-->
            <!--                    />-->
            <!--                    <template-->
            <!--                      v-if="row.id_currencies"-->
            <!--                      #append-->
            <!--                    >-->
            <!--                      <b-input-group-text>{{ row.currency }}</b-input-group-text>-->
            <!--                    </template>-->
            <!--                  </b-input-group>-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                  <div-->
            <!--                    v-if="row.price"-->
            <!--                    class="font-weight-light"-->
            <!--                  >-->
            <!--                    Birim Fiyatı : {{ row.price | toCurrency }}-->
            <!--                    <span class="text-primary">-->
            <!--                      {{ row.currency }}-->
            <!--                    </span>-->
            <!--                  </div>-->

            <!--                </validation-provider>-->
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Vergi Oranı"
            label-for="id_taxes"
          >
            <h4>{{ row.taxes }}</h4>
            <!--              <validation-provider-->
            <!--                #default="{ errors }"-->
            <!--                name="Vergi Oranı"-->
            <!--                rules="required"-->
            <!--              >-->
            <!--                <v-select-->
            <!--                  id="id_taxes"-->
            <!--                  v-model="row.id_taxes"-->
            <!--                  :options="taxes"-->
            <!--                  label="title"-->
            <!--                  :reduce="item => item.id"-->
            <!--                  placeholder="Seçiniz"-->
            <!--                />-->
            <!--                <small class="text-danger">{{ errors[0] }}</small>-->
            <!--              </validation-provider>-->
          </b-form-group>
        </b-col>
        <!--        <b-col md="auto">-->
        <!--          <div class="text-right">-->
        <!--            <b-button-->
        <!--              variant="outline-danger"-->
        <!--              size="sm"-->
        <!--              pill-->
        <!--              class="btn-icon mt-1"-->
        <!--              @click="removeItem(row.id,productType,index)"-->
        <!--            >-->
        <!--              <FeatherIcon icon="TrashIcon" />-->
        <!--            </b-button>-->
        <!--          </div>-->
        <!--        </b-col>-->
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Not"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              v-model="row.content"
              placeholder="Not"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea, /* BInputGroup, BDropdown, BDropdownItem , BButton, BInputGroupText */
} from 'bootstrap-vue'
import { /* ValidationProvider, */ localize } from 'vee-validate'
// import Cleave from 'vue-cleave-component'
import { required } from '@validations'
// import vSelect from 'vue-select'

export default {
  name: 'PricingCard',
  components: {
    // BButton,
    // vSelect,
    // BInputGroup,
    BFormTextarea,
    BFormGroup,
    // ValidationProvider,
    BRow,
    BCol,
    // Cleave,
    // BDropdown,
    // BDropdownItem,
    // BInputGroupText,
  },
  props: {

    removeItem: {
      type: Function,
      required: true,
    },
    productType: {
      type: Number,
      required: true,
    },
    productGroupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      selectedUnitTitle: [],
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    offerData() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    taxes() {
      return this.$store.getters['taxes/dataList']
    },
    products() {
      return this.$store.getters['products/dataList']
    },
    dataList() {
      return this.$store.getters['proformaInvoices/productList']
    },
    productLists() {
      return this.filterProduct()
    },
    units() {
      return this.$store.getters['units/dataList']
    },
    currencies() {
      return this.$store.getters['currencies/dataList']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    selectDropdown(item, index) {
      const foundRow = this.units.find(unit => unit.id === item)
      this.dataList[this.productType].productList[index].id_units = foundRow.id
      this.selectedUnitTitle[index] = foundRow.title
    },
    // eslint-disable-next-line consistent-return
    filterProduct() {
      return this.products.filter(item => item.id_product_groups === this.productGroupId)
    },
  },

}
</script>
