<template>
  <div>
    <b-card title="Proforma Bilgileri">
      <b-row>
        <b-col>
          <proforma-date />
        </b-col>
        <b-col>
          <proforma-no />
        </b-col>
        <b-col
          cols="12"
        >
          <payment-type />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <company />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <delivery-location />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <delivery-type />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <origin />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <deadline />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <bank-accounts />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <customer-related-person />
        </b-col>
        <b-col cols="12">
          <notes />
        </b-col>
      </b-row>
    </b-card>
    <template v-if="dataList.length > 0">
      <price-list-card
        :remove-item="removeItem"
      />
    </template>
    <template v-else>
      <b-card no-body>
        <b-card-body>
          <b-alert
            variant="warning"
            show
          >
            <div class="alert-body text-center">
              <div class="mb-1">
                <!--                Proforma ürünlerini eklemek istediğiniz seçeneği seçin.-->
                Proforma içeriğinde ürün bulunmuyor.
              </div>
              <b-button
                variant="primary"
                size="sm"
                @click="addItems"
              >
                Ürünleri Teklif İçeriğinden Getir
              </b-button>
              <!--              <b-button-->
              <!--                variant="primary"-->
              <!--                size="sm"-->
              <!--                class="ml-2"-->
              <!--                @click="newProductRow"-->
              <!--              >-->
              <!--                Ürünleri Kendim Gireceğim-->
              <!--              </b-button>-->
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </template>
    <b-card title="Proforma Özel Şartları">
      <proforma-terms />
      <proforma-term />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BAlert,
} from 'bootstrap-vue'
import ProformaDate from '@/views/Admin/Proforma_invoices/elements/ProformaDate.vue'
import ProformaNo from '@/views/Admin/Proforma_invoices/elements/ProformaNo.vue'
import PaymentType from '@/views/Admin/Proforma_invoices/elements/PaymentType.vue'
import Notes from '@/views/Admin/Proforma_invoices/elements/Notes.vue'
import DeliveryLocation from '@/views/Admin/Proforma_invoices/elements/DeliveryLocation.vue'
import DeliveryType from '@/views/Admin/Proforma_invoices/elements/DeliveryType.vue'
import Origin from '@/views/Admin/Proforma_invoices/elements/Origin.vue'
import Deadline from '@/views/Admin/Proforma_invoices/elements/Deadline.vue'
import BankAccounts from '@/views/Admin/Proforma_invoices/elements/BankAccounts.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import PriceListCard from '@/views/Admin/Proforma_invoices/Components/PriceListCard.vue'
import Company from '@/views/Admin/Proforma_invoices/elements/Company.vue'
import CustomerRelatedPerson from '@/views/Admin/Proforma_invoices/elements/CustomerRelatedPerson.vue'
import ProformaTerms from '@/views/Admin/Proforma_invoices/elements/ProformaTerms.vue'
import ProformaTerm from '@/views/Admin/Proforma_invoices/elements/ProformaTerm.vue'

export default {
  name: 'ProformaForm',
  components: {
    ProformaTerm,
    ProformaTerms,
    CustomerRelatedPerson,
    Company,
    BAlert,
    PriceListCard,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BCard,
    ProformaDate,
    ProformaNo,
    PaymentType,
    DeliveryLocation,
    DeliveryType,
    Origin,
    Deadline,
    BankAccounts,
    Notes,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    addItems: {
      type: Function,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    dataList() {
      return this.$store.getters['proformaInvoices/productList']
    },
    OfferList() {
      return this.$store.getters['offerLines/dataList']
    },

  },
  methods: {
    newProductRow() {
      this.$store.dispatch('proformaInvoices/getNewProductList')
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
