<template>
  <div>
    <div
      v-for="(row,index) in dataList"
      :key="index"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            {{ row.title }}
          </b-card-title>
        </b-card-header>
        <b-card-body v-if="row.productList.length > 0">
          <price-card
            :product-type="index"
            :product-group-id="row.id"
            :remove-item="removeItem"
          />
        </b-card-body>
        <!--        <b-card-body>-->
        <!--          <b-button-->
        <!--            variant="primary"-->
        <!--            size="sm"-->
        <!--            @click="addRow(index)"-->
        <!--          >-->
        <!--            <feather-icon icon="PlusIcon" />-->
        <!--            Yeni Ürün-->
        <!--          </b-button>-->
        <!--        </b-card-body>-->
        <b-card-footer>
          <b-row v-if="row.productList.length > 0">
            <b-col
              class="text-right"
              cols="9"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                />
                <b-media-body
                  v-if="row.productList.length > 0 && row.productList[0].price"
                  class="my-auto"
                >
                  <b-card-text class="font-small-3 mb-0">
                    Teklif Toplam Tutar
                  </b-card-text>
                  <h4

                    class="font-weight-bolder mb-0"
                  >
                    <template v-if="row.productList.length > 0 ">
                      {{ calculateTotal(index,'price') | toCurrency }}<span class="text-primary"> {{ row.productList[0].currency }}</span>
                    </template>
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col class="text-right">
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                />

                <b-media-body
                  v-if="dataItem.currency ||row.productList.length>0 && row.productList[0].listed_price"
                  class="my-auto"
                >
                  <b-card-text class="font-small-3 mb-0">
                    Liste Fiyatı
                  </b-card-text>
                  <h4 class="font-weight-bolder mb-0">
                    <template v-if="dataList.length > 0 ">
                      {{ calculateTotal(index,'listed_price') | toCurrency }}<span class="text-primary"> {{ row.productList[0].currency }}</span>
                    </template>
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </div>
    <b-row class="mb-2">
      <!--      <b-col>-->
      <!--        <div-->
      <!--          v-if="dataList[0].productList[0].price > 0"-->
      <!--          class="text-left"-->
      <!--        >-->
      <!--          <h3>{{ calculateProductTotal('price') | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h3>-->
      <!--          <h5>Toplam Teklif Ürün Tutarı</h5>-->
      <!--        </div>-->
      <!--      </b-col>-->
      <b-col>
        <div class="text-right">
          <!--<h3>{{ calculateProductTotal('listed_price') | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h3>-->
          <template v-if="dataItem.discount_amount">
            <del>
              <h5 class="text-muted">{{ dataItem.totalPrice | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h5>
            </del>
            <h3>{{ (dataItem.totalPrice - dataItem.discount_amount) | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h3>
            <h5>Toplam Genel İndirimli(%{{ dataItem.discount_rate | toNumber }} İskonto) Ürün Tutarı</h5>
          </template>
          <template v-else>
            <h3>{{ dataItem.totalPrice | toCurrency }} <span class="text-primary"> {{ dataList[0].productList[0].currency }}</span></h3>
            <h5>Toplam Genel Ürün Tutarı</h5>
          </template>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BCardFooter,
  BCardHeader, BCardText,
  BCardTitle, BCol, BMedia, BMediaAside, BMediaBody, BRow,

} from 'bootstrap-vue'
import PriceCard from '@/views/Admin/Proforma_invoices/Components/PricingCard.vue'

export default {
  name: 'PriceListCard',
  components: {
    BMediaAside,
    BMedia,
    BCardText,
    BMediaBody,
    BCol,
    BCardFooter,
    BRow,
    PriceCard,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  props: {
    removeItem: {
      type: Function,
      required: true,
    },
  },

  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    dataList() {
      return this.$store.getters['proformaInvoices/productList']
    },
    offerData() {
      return this.$store.getters['offers/dataItem']
    },
  },
  created() {
    this.getTaxes()
    this.getUnits()
    this.getProducts()
  },
  methods: {
    getTaxes() {
      this.$store.dispatch('taxes/getDataList')
    },
    getProducts() {
      this.$store.dispatch('products/getDataList')
    },
    getUnits() {
      this.$store.dispatch('units/getDataList')
    },
    addRow(index) {
      this.dataList[index].productList.push({
        id: null,
        notes: null,
        amount: null,
        price: null,
        id_products: null,
        id_projects: null,
        id_units: null,
        discounted_price: null,
        discount_amount: null,
        discount_rate: null,
        discountStatus: false,
        id_currencies: this.offerData.id_currencies ? this.offerData.id_currencies : null,
        currency: this.offerData.currency ? this.offerData.currency : null,
        id_taxes: null,
      })
    },
    calculateProductTotal() {
      const totalPrice = []
      for (let i = 0; i < this.dataList.length; i += 1) {
        for (let l = 0; l < this.dataList[i].productList.length; l += 1) {
          totalPrice.push(this.dataList[i].productList[l])
        }
      }
      // const total = totalPrice.reduce((acc, item) => acc + ((parseFloat(output === 'price' ? item.price : item.listed_price) ? parseFloat(output === 'price' ? item.price : item.listed_price) : 1)) * (parseFloat(item.amount) ? parseFloat(item.amount) : 1), 0)
      const total = totalPrice.reduce((acc, item) => {
        const price = parseFloat(item.price)
        const listedPrice = parseFloat(item.listed_price)
        const amount = parseFloat(item.amount)

        if (amount !== 1 && (price !== 0 || listedPrice !== 0)) {
          // eslint-disable-next-line no-param-reassign
          acc += (price || listedPrice || 1) * (amount || 1)
        }

        return acc
      }, 0)
      return isNaN(total) ? 0 : total
    },
    calculateTotal(index, output) {
      // const priceData = this.dataList[index].productList
      // const total = priceData.reduce((acc, item) => acc + ((parseFloat(output === 'price' ? item.price : item.listed_price) ? parseFloat(output === 'price' ? item.price : item.listed_price) : 1)) * (parseFloat(item.amount) ? parseFloat(item.amount) : 1), 0)
      // return isNaN(total) ? 0 : total
      const priceData = this.dataList[index].productList
      const total = priceData.reduce((acc, item) => {
        const price = parseFloat(output === 'price' ? item.price : item.listed_price)
        const amount = parseFloat(item.amount)

        if (!isNaN(price) && price !== 0 && !isNaN(amount)) {
          acc += price * amount
        }

        return acc
      }, 0)

      return isNaN(total) ? 0 : total
    },
  },
}
</script>
