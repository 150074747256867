<template>
  <b-form-group
    label="Proforma Özel Şartları"
    label-for="id_proforma_terms"
  >
    <v-select
      id="id_proforma_terms"
      v-model="id_proforma_terms"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      @input="getTerm"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProformaTerms',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      id_proforma_terms: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
    dataList() {
      return this.$store.getters['proformaTerms/dataList']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getTerm() {
      if (this.id_proforma_terms) {
        this.$store.dispatch('proformaTerms/getDataItem', this.id_proforma_terms)
          .then(response => {
            this.dataItem.terms = response.content
          })
      }
    },
    getData() {
      this.$store.dispatch('proformaTerms/getDataList', {
        select: [
          'proforma_terms.id AS id',
          'proforma_terms.title AS title',
        ],
      })
    },
  },
}
</script>
